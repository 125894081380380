import SkeletonLayout from '@/components/skeletons/SkeletonLayout';
import SkeletonDetail from '@/components/skeletons/SkeletonDetail';
import store from '@/store/index';
import DevelopersSkeleton from '@/pages/Settings/Developers/DevelopersSkeleton';
import Request from '@/util/api/shopnow';
import integrations from '@/util/api/integrations';
import { enablePageScroll } from 'scroll-lock';
import Store from '@/store';
import { featureFlags } from '@/util/schemas/featureFlags';
import { shopSettings } from '@/util/schemas/ShopSettings';
import { permissions } from '@/util/schemas/permissions';
import SBLWW from '@/constants/shipByLoopWorldwide';

const settings = new Request();

function handleGorgiasInstall(to, next) {
  const subdomain = to.query?.subdomain;
  const createDeliveryErrorTickets = window.localStorage.getItem('createDeliveryErrorTickets');

  integrations.createHttpIntegration('gorgias', subdomain, (createDeliveryErrorTickets === 'true'))
    .then(response => {
      next('/settings/integrations');
      if (response.data?.message) {
        store.dispatch('setToast', {
          message: response.data?.message,
          type: 'success',
        });
      }
    })
    .catch(err => {
      next('/settings/integrations');
      if (err.response.data?.error) {
        store.dispatch('setToast', {
          message: err.response.data?.error,
          type: 'error',
        });
      }
    }).finally(() => {
      window.localStorage.removeItem('createDeliveryErrorTickets');
    });
}

export default [
  {
    path: '/settings/general',
    component: () => import('@/pages/Settings/ReturnPolicy/PolicyList'),
    name: 'PolicyList',
    meta: {
      layout: 'app',
      title: 'General',
      skeleton: () => SkeletonLayout,
      about: `Manage settings for all returns`,
      icon: 'settings-gear',
      color: '#000000',
      action: 'settings/policies/getPageData'
    },
    beforeEnter: (to, from, next) => {
      enablePageScroll();
      next();
    }
  },
  {
    path: '/settings/return-policy',
    component: () => import('@/pages/Settings/PolicyZones/ZoneList'),
    name: 'ZoneList',
    meta: {
      layout: 'app',
      title: 'Return policies',
      about: `Manage settings for location-based return policies`,
      icon: 'simple-globe',
      color: '#DE3618',
      loader: false
    }
  },
  {
    path: '/settings/return-policy/add',
    component: () => import('@/pages/Settings/PolicyZones/ZoneDetail'),
    name: 'ZoneAdd',
    meta: {
      layout: 'app',
      title: 'Create return policy',
      loader: false
    }
  },
  {
    path: '/settings/return-policy/:id',
    component: () => import('@/pages/Settings/PolicyZones/ZoneDetail'),
    name: 'ZoneEdit',
    meta: {
      layout: 'app',
      title: 'Edit return policy',
      loader: false
    },
    beforeEnter: (to, from, next) => {
      enablePageScroll();
      next();
    }
  },
  {
    path: '/settings/warranty-policy',
    component: () => import('@/pages/Warranties/WarrantiesList'),
    name: 'WarrantiesList',
    meta: {
      layout: 'app',
      title: 'Warranty policies',
      about: 'Create and maintain warranty policies.',
      icon: 'tag',
      color: '#2B33FF',
      loader: false,
    },
    beforeEnter: (to, from, next) => {
      if (!Store.getters.getSetting('WARRANTIES_ENABLED')) {
        next('/settings');
      } else {
        next();
      }
    },
  },
  {
    path: '/settings/warranty-policy/add',
    component: () => import('@/pages/Warranties/WarrantiesDetail'),
    name: 'WarrantiesDetailAdd',
    meta: {
      layout: 'app',
      title: 'Warranty policy',
      about: 'Create and maintain warranty policies.',
      loader: false,
    },
    beforeEnter: (to, from, next) => {
      if (!Store.getters.getSetting('WARRANTIES_ENABLED')) {
        next('/settings');
      } else {
        enablePageScroll();
        store.dispatch('settings/warranties/reset');
        next();
      }
    },
  },
  {
    path: '/settings/warranty-policy/:id',
    component: () => import('@/pages/Warranties/WarrantiesDetail'),
    name: 'WarrantiesDetailEdit',
    meta: {
      layout: 'app',
      title: 'Warranty policy',
      about: 'Create and maintain warranty policies.',
      loader: false,
    },
    beforeEnter: (to, from, next) => {
      if (!Store.getters.getSetting('WARRANTIES_ENABLED')) {
        next('/settings');
      } else {
        enablePageScroll();
        next();
      }
    },
  },
  {
    path: '/settings/protection',
    component: () => import('@/pages/Settings/ShippingProtection/ShippingProtection'),
    name: 'ShippingProtection',
    meta: {
      layout: 'app',
      title: 'Package Protection',
      skeleton: () => SkeletonLayout,
    },
    beforeEnter(to, from, next) {
      if (store.getters.getSetting(shopSettings.SHIPPING_PROTECTION_ENABLED)) {
        next();
      } else {
        next('/settings');
      }
    }
  },
  {
    path: '/workflows',
    component: () => import('@/pages/Workflows/WorkflowsList'),
    name: 'WorkflowsList',
    meta: {
      layout: 'app',
      title: 'Workflows',
      about: `Configure workflows to handle custom return scenarios`,
      icon: 'settings-workflow',
      color: '#2B33FF',
      loader: false,
      action: 'settings/workflows/getPageData'
    }
  },
  {
    path: '/workflows/testbench',
    component: () => import('@/pages/Workflows/Testbench/TestbenchPage'),
    name: 'WorkflowsTestbench',
    meta: {
      layout: 'app',
      title: 'Workflows Testbench',
      about: `Test workflows in a sandbox environment`,
      loader: false,
    },
    beforeEnter: (_to, _from, next) => {
      if (!Store.getters.hasFeature(featureFlags.WORKFLOWS_TESTBENCH)) {
        next('/workflows');
      }
      next();
    },
  },
  {
    path: '/workflows/static-templates',
    component: () => import('@/pages/Workflows/StaticTemplates/StaticTemplatesList'),
    name: 'StaticTemplatesList',
    meta: {
      layout: 'app',
      title: 'Workflow templates',
      loader: false
    },
    beforeEnter: (_to, _from, next) => {
      if (!Store.getters.hasFeature(featureFlags.STATIC_WORKFLOW_TEMPLATES)) {
        next('/workflows');
      }
      next();
    },
  },
  {
    path: '/workflows/add',
    component: () => import('@/pages/Workflows/WorkflowsDetail'),
    name: 'WorkflowsDetailAdd',
    meta: {
      layout: 'app',
      layoutType: 'full',
      title: 'Workflows detail',
      showBanner: false,
      action: 'settings/workflows/getPageData'
    }
  },
  {
    path: '/workflows/:uuid',
    component: () => import('@/pages/Workflows/WorkflowsDetail'),
    name: 'WorkflowsDetailEdit',
    meta: {
      layout: 'app',
      layoutType: 'full',
      title: 'Workflows detail',
      showBanner: false,
      action: 'settings/workflows/getPageData'
    }
  },
  {
    path: '/settings/destinations',
    component: () => import('@/pages/Settings/Destinations/DestinationsList'),
    name: 'DestinationsList',
    meta: {
      layout: 'app',
      title: 'Destinations',
      skeleton: () => SkeletonLayout,
      about: 'Manage the places your returned items are received',
      icon: 'pin',
      color: '#DA6094',
      loader: false
    }
  },
  {
    path: '/settings/destinations/add',
    component: () => import('@/pages/Settings/Destinations/DestinationsDetail'),
    name: 'DestinationsDetailAdd',
    meta: {
      layout: 'app',
      title: 'Add destination',
      loader: false
    }
  },
  {
    path: '/settings/destinations/:id',
    component: () => import('@/pages/Settings/Destinations/DestinationsDetail'),
    name: 'DestinationsDetailEdit',
    meta: {
      layout: 'app',
      title: 'Edit destination',
      loader: false
    },
    beforeEnter: (to, from, next) => {
      enablePageScroll();
      next();
    }
  },
  {
    path: '/settings/shipping',
    component: () => import('@/pages/Settings/MultiShipping/ShippingPage'),
    name: 'Shipping',
    meta: {
      layout: 'app',
      title: 'Shipping services',
      about: `Manage how returns are shipped back to you`,
      icon: 'settings-shipping',
      color: '#B7B2EB'
    },
    beforeEnter: (to, from, next) => {
      enablePageScroll();
      next();
    }
  },
  {
    path: '/settings/shipping/service/:slug',
    component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ConfigureShippingService'),
    name: 'ShippingService',
    meta: {
      layout: 'app',
      title: 'Configure shipping service',
      about: `View and manage this shipping service configuration`,
      icon: 'settings-shipping'
    },
    props: true
  },
  {
    path: '/settings/shipping/service/ship-by-loop',
    component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoop'),
    name: 'ShipByLoop',
    meta: {
      layout: 'app',
      title: 'Ship by Loop US Domestic',
      about: `View and manage the Ship by Loop US Domestic shipping service`,
    },
    children: [
      {
        path: 'account-info',
        name: 'AccountInfo',
        component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoop/AccountInfoForm'),
        props: true,
      },
      {
        path: 'add-carrier',
        component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoop/AddCarrierModal'),
        props: true,
        children: [
          {
            path: 'carriers',
            name: 'CarrierSelect',
            component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoop/AddCarrierPages/CarrierSelect'),
            props: true,
          },
          {
            path: 'account',
            name: 'AccountSelect',
            component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoop/AddCarrierPages/AccountSelect'),
            props: true,
          },
          {
            path: 'payment',
            name: 'Payment',
            component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoop/AddCarrierPages/Payment'),
            props: true,
          },
          {
            path: 'payment-form',
            name: 'PaymentForm',
            component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoop/AddCarrierPages/PaymentForm'),
            props: true,
          },
          {
            path: 'business-address',
            name: 'BusinessAddress',
            component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoop/AddCarrierPages/BusinessAddress'),
            props: true,
          },
          {
            path: 'recharge',
            name: 'Recharge',
            component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoop/AddCarrierPages/Recharge'),
            props: true,
          },
          {
            path: 'connecting',
            name: 'Connect',
            component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoop/AddCarrierPages/Connect'),
            props: true,
          },
          {
            path: 'business-info',
            name: 'BusinessInfo',
            component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoop/AddCarrierPages/BusinessInfo'),
            props: true,
          },
          {
            path: 'invoice-info',
            name: 'InvoiceInfo',
            component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoop/AddCarrierPages/InvoiceInfo'),
            props: true,
          },
          {
            path: 'credentials',
            name: 'Credentials',
            component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoop/AddCarrierPages/Credentials'),
            props: true,
          },
          {
            path: 'sorry',
            name: 'SorryPage',
            component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoop/AddCarrierPages/SorryPage'),
            props: true,
          },
          {
            path: 'error',
            name: 'ErrorPage',
            component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoop/AddCarrierPages/ErrorPage'),
            props: true,
          }
        ],
      },
    ],
    props: true,
    beforeEnter: (to, from, next) => {
      enablePageScroll();
      next();
    },
  },
  {
    path: '/settings/shipping/service/ship-by-loop-worldwide',
    component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoopWorldwide'),
    name: 'ShipByLoopWorldwide',
    meta: {
      layout: 'app',
      title: 'Ship By Loop Worldwide',
      about: `View and manage the Ship By Loop Worldwide shipping service`,
    },
    children: [
      {
        path: 'account-info',
        name: 'AccountInfo',
        component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoopWorldwide/AccountInfoForm'),
        props: true,
      },
      {
        path: 'account-summary',
        name: 'AccountSummary',
        component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoopWorldwide/AccountSummary'),
        props: true,
      },
      {
        path: 'payment',
        name: 'PaymentInfo',
        component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoopWorldwide/PaymentForm'),
        props: true,
      },
      {
        path: 'manage-payment-methods',
        name: 'ManagePaymentMethods',
        component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoopWorldwide/ManagePaymentMethods/ManagePaymentMethods'),
        props: true,
      },
      {
        path: 'recharge',
        name: 'RechargeInfo',
        component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoopWorldwide/RechargeForm'),
        props: true,
      },
      {
        path: 'carriers',
        name: 'CarrierSelection',
        component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoopWorldwide/CarrierSelection.vue'),
        props: true,
      },
      {
        path: 'config',
        name: 'MainConfiguration',
        component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoopWorldwide/MainConfiguration.vue'),
        props: true,
      },
      {
        path: 'royalmail/:carrierId',
        name: SBLWW.BYOA_ROUTE_NAMES.royalmail,
        component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoopWorldwide/RoyalMail.vue'),
        props: true,
        meta: {
          layout: 'app',
          title: 'Add Royal Mail',
        },
      },
      {
        path: 'australiapost/:carrierId',
        name: SBLWW.BYOA_ROUTE_NAMES.australiapost,
        component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoopWorldwide/AustraliaPost.vue'),
        props: true,
        meta: {
          layout: 'app',
          title: 'Add Australia Post',
        },
      },
      {
        path: 'byoa-account-summary',
        name: 'ByoaAccountSummary',
        component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoopWorldwide/ByoaAccountSummary'),
        props: true,
      },
    ],
    props: true,
    beforeEnter: (to, from, next) => {
      enablePageScroll();
      next();
    },
  },
  {
    path: '/settings/shipping/service/easy-post/carrier-testing',
    component: () => import('@/pages/Settings/MultiShipping/CarrierTesting/EasyPostCarrierTesting'),
    name: 'CarrierTesting',
    meta: {
      layout: 'app',
      title: 'Carrier Testing',
      skeleton: () => SkeletonLayout,
      loader: true
    },
  },
  {
    path: '/settings/shipping/service/:integration/carrier/:slug',
    component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/Carriers/ShippingCarrierSettingsPage'),
    name: 'ShippingCarrierSettings',
    meta: {
      layout: 'app',
      title: 'Carrier',
      about: `Manage your carrier settings`,
      icon: 'settings-shipping'
    },
    props: true
  },
  {
    path: '/settings/shipping/ship-by-loop/reports',
    component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoop/Reports'),
    name: 'ShipByLoopReports',
    meta: {
      layout: 'app',
      title: 'Ship by Loop US Domestic Reports',
      about: `Ship by Loop US Domestic Reports`,
    },
    props: true
  },
  {
    path: '/settings/shipping/ship-by-loop-worldwide/reports',
    component: () => import('@/pages/Settings/MultiShipping/ShippingServices/ConfigureShippingService/ShipByLoopWorldwide/Reports'),
    name: 'ShipByLoopWorldwideReports',
    meta: {
      layout: 'app',
      title: 'Ship by Loop Worldwide Reports',
      about: `Ship by Loop Worldwide Reports`,
    },
    props: true
  },
  {
    path: '/settings/locations',
    component: () => import('@/pages/Settings/Locations/LocationsPage'),
    name: 'LocationsPage',
    meta: {
      layout: 'app',
      title: 'Locations',
      skeleton: () => SkeletonLayout,
      about: 'Manage your fulfillable locations',
      icon: 'pin',
      color: '#DA6094',
      loader: false
    }
  },
  // Notifications pages
  {
    path: '/settings/notifications',
    component: () => import('@/pages/Settings/Notifications/NotificationsList'),
    name: 'NotificationsList',
    meta: {
      layout: 'app',
      title: 'Notifications',
      skeleton: () => SkeletonLayout,
      about: 'Manage notifications sent to you and your customers',
      icon: 'settings-mail',
      color: '#47C1BF',
      action: 'settings/notifications/getPageData'
    }
  },
  // TODO: Turn these into proper sub routes
  {
    path: '/settings/notifications/edit/:id',
    component: () => import('@/pages/Settings/Notifications/EmailEdit'),
    name: 'EmailEdit',
    meta: {
      layout: 'app',
      title: 'Edit Template',
      skeleton: () => SkeletonDetail,
      action: 'settings/notifications/getPageData',
      loader: false
    }
  },
  {
    path: '/settings/notifications/customize/:id',
    component: () => import('@/pages/Settings/Notifications/EmailView'),
    name: 'EmailView',
    meta: {
      layout: 'app',
      title: 'Customize Email Templates',
      skeleton: () => SkeletonDetail,
      action: 'settings/notifications/getPageData',
      loader: false
    }
  },
  {
    path: '/settings/reasons',
    component: () => import('@/pages/Settings/Reasons/ReasonsList'),
    name: 'ReasonsList',
    meta: {
      layout: 'app',
      title: 'Reasons',
      skeleton: () => SkeletonLayout,
      about: 'Manage your return reasons in the returns portal',
      icon: 'settings-reasons',
      color: '#9C6ADE',
      action: 'settings/reasons/getPageData'
    }
  },
  {
    path: '/settings/reasons/library',
    component: () => import('@/pages/Settings/Reasons/ReasonsLibraryPage'),
    name: 'ReasonsLibraryPage',
    meta: {
      layout: 'app',
      title: 'Reasons library',
      loader: false,
      action: 'settings/customizations/getContentData'
    }
  },
  {
    path: '/settings/reasons/groups/:id',
    component: () => import('@/pages/Settings/Reasons/ReasonGroupDetail'),
    name: 'ReasonGroupDetail',
    meta: {
      layout: 'app',
      title: 'Reason Group',
      loader: false
    }
  },
  {
    path: '/settings/customizations',
    component: () => import('@/pages/Settings/Customizations/CustomizationsList'),
    name: 'CustomizationsList',
    meta: {
      layout: 'app',
      title: 'Portal customizations',
      skeleton: () => SkeletonLayout,
      about: 'Manage what your customer portal looks like',
      icon: 'settings-brush',
      color: '#007ACE',
      action: 'settings/customizations/getPageData'
    }
  },
  {
    path: '/settings/customizations/content',
    component: () => import('@/pages/Settings/Customizations/ContentCustomizations'),
    name: 'ContentCustomizations',
    meta: {
      layout: 'app',
      title: 'Content customizations',
      skeleton: () => SkeletonLayout,
      action: 'settings/customizations/getContentData'
    }
  },
  {
    path: '/settings/customizations/editor',
    component: () => import('@/pages/Settings/Customizations/CustomizationsEditor'),
    name: 'CustomizationsEditor',
    meta: {
      layout: 'app',
      layoutType: 'full',
      showBanner: false,
      title: 'Customizations editor'
    }
  },
  {
    path: '/settings/billing',
    component: () => import('@/pages/Settings/Billing/BillingPortal'),
    name: 'BillingPortal',
    meta: {
      layout: 'app',
      title: 'Billing',
      about: 'Manage your billing info and view your invoices',
      icon: 'settings-billing',
      color: '#50B83C',
    }
  },
  // Account pages
  {
    path: '/settings/account',
    component: () => import('@/pages/Settings/Account/AccountList'),
    name: 'AccountList',
    meta: {
      layout: 'app',
      title: 'Account',
      skeleton: () => SkeletonLayout,
      about: 'Manage your accounts and users permissions',
      icon: 'settings-user',
      color: '#EDC200',
      action: 'settings/account/getPageData'
    }
  },
  {
    path: '/settings/account/edit/:id',
    component: () => import('@/pages/Settings/Account/AccountEdit'),
    name: 'AccountEdit',
    meta: {
      layout: 'app',
      title: 'Edit account',
      skeleton: () => SkeletonLayout,
      action: 'settings/account/getUserData'
    }
  },
  {
    path: '/settings/account/add',
    component: () => import('@/pages/Settings/Account/AccountAdd'),
    name: 'AccountAdd',
    meta: {
      layout: 'app',
      title: 'Add staff account',
      skeleton: () => SkeletonLayout,
    }
  },
  // Developers page
  {
    path: '/settings/developers',
    component: () => import('@/pages/Settings/Developers/DevelopersPage'),
    name: 'DevelopersPage',
    meta: {
      layout: 'app',
      title: 'Developers',
      skeleton: () => DevelopersSkeleton,
      about: 'Manage your webhooks and API keys',
      icon: 'developers',
      color: '#F49342',
      action: 'settings/developers/getPageData'
    }
  },
  {
    path: '/settings/bundles',
    component: () => import('@/pages/Settings/Bundles/BundleList'),
    name: 'Bundles',
    meta: {
      layout: 'app',
      title: 'Bundles',
      skeleton: () => SkeletonLayout,
      about: 'Manage how bundles are returned',
      icon: 'settings-bundle',
      color: '#25B8F6',
      action: 'settings/bundles/getBundles',
    }
  },
  {
    path: '/settings/integrations',
    component: () => import('@/pages/Settings/Integrations/IntegrationsList'),
    name: 'Integrations',
    meta: {
      layout: 'app',
      title: 'Integrations',
      skeleton: () => SkeletonLayout,
      about: 'Manage your integrations.',
      icon: 'settings-integrations',
      color: '#999999',
    }
  },
  {
    path: '/settings/integration/:name',
    component: () => import('@/pages/Settings/Integrations/Onboarding/Onboarding'),
    name: 'Integration Onboarding Tasks',
    props: true,
    meta: {
      layout: 'app',
      title: 'Integration Onboarding Tasks',
      skeleton: () => SkeletonLayout,
      about: 'Self-service integrations to automate your process end-to-end. View all available integrations here.',
      icon: 'settings-integrations',
      color: '#999999',
    }
  },
  {
    path: '/settings/bundles/add',
    component: () => import('@/pages/Settings/Bundles/BundleDetail'),
    name: 'BundleDetailAdd',
    meta: {
      layout: 'app',
      title: 'Create Bundle',
      skeleton: () => SkeletonLayout,
      action: 'settings/bundles/getPageData',
    }
  },
  {
    path: '/settings/bundles/:id',
    component: () => import('@/pages/Settings/Bundles/BundleDetail'),
    name: 'BundleDetailEdit',
    meta: {
      layout: 'app',
      title: 'Edit Bundle',
      skeleton: () => SkeletonLayout,
      action: 'settings/bundles/getBundleData',
    }
  },
  {
    path: '/integrations/oauth/:name',
    name: 'IntegrationsOAuth',
    meta: {
      user: false
    },
    beforeEnter(to, from, next) {
      settings.oauth(to.fullPath)
        .then((response) => {
          if (to.fullPath.includes('gorgias')) {
            const gorgiasInstallTerms = ['subdomain', 'code'];

            if (gorgiasInstallTerms.every(term => to.fullPath.includes(term))) {
              return handleGorgiasInstall(to, next);
            }
          }
          next('/settings/integrations');
          if (response.data?.message) {
            store.dispatch('setToast', {
              message: response.data.message,
              type: 'success',
            });
          }
        })
        .catch(err => {
          if (err.response?.status === 401) {
            sessionStorage.setItem('deep-link', to.fullPath);
          }
          next('/settings/integrations');
          if (err.response.data?.error) {
            store.dispatch('setToast', {
              message: err.response.data.error,
              type: 'error',
            });
          }
        });
    }
  },
  // Point of Sale
  {
    path: '/settings/point-of-sale',
    component: () => import('@/pages/Settings/PointOfSale/PointOfSaleList'),
    name: 'PointOfSaleList',
    meta: {
      layout: 'app',
      title: 'Point of Sale',
      skeleton: () => SkeletonLayout,
      about: 'Manage your Loop Point of Sale App',
      icon: 'settings-pop',
      color: '#71C20E',
    },
    props: true,
    beforeEnter: (to, from, next) => {
      if (store.getters.getSetting('ENABLE_POINT_OF_SALE') && store.getters.hasPermission(permissions.MANAGE_POINT_OF_SALE)) {
        next();
      } else {
        next('/settings/get-point-of-sale');
      }
    }
  },
  {
    path: '/settings/get-point-of-sale',
    component: () => import('@/pages/Settings/PointOfSale/PointOfSaleOffStatePage.vue'),
    name: 'Point of Sale Off State',
    meta: {
      layout: 'app',
      layoutType: 'wide',
      title: 'Get POS',
    }
  },
  {
    path: '/settings/point-of-sale/add',
    component: () => import('@/pages/Settings/PointOfSale/AssociateAdd'),
    name: 'AssociateAdd',
    meta: {
      layout: 'app',
      title: 'Add associate account',
      skeleton: () => SkeletonLayout,
    }
  },
  {
    path: '/settings/point-of-sale/:id',
    component: () => import('@/pages/Settings/PointOfSale/AssociateEdit'),
    name: 'AssociateEdit',
    meta: {
      layout: 'app',
      title: 'Edit associate account',
      skeleton: () => SkeletonLayout,
    },
    props: true,
  },
  {
    path: '/settings/tracking',
    component: () => import('@/pages/Tracking/TrackingSetupPage'),
    name: 'Tracking',
    meta: {
      layout: 'app',
      title: 'Tracking',
      skeleton: () => SkeletonLayout,
      about: 'Manage tracking settings.',
      icon: 'settings-tracking',
      color: '#999999',
    }
  },
  {
    path: '/settings/order-tracking',
    component: () => import('@/pages/Tracking/OrderTracking/OrderTrackingSetupPage.vue'),
    name: 'Order Tracking',
    meta: {
      layout: 'app',
      title: 'Order Tracking',
      skeleton: () => SkeletonLayout,
      about: 'Manage tracking settings.',
      icon: 'settings-tracking',
      color: '#999999',
    },
    beforeEnter(to, from, next) {
      if (store.getters.getSetting(shopSettings.ORDER_TRACKING_ALLOWED)) {
        next();
      } else {
        next('/settings/get-order-tracking');
      }
    }
  },
  {
    path: '/settings/get-order-tracking',
    component: () => import('@/pages/Tracking/OrderTrackingOffStatePage.vue'),
    name: 'Order Tracking Off State',
    meta: {
      layout: 'app',
      layoutType: 'wide',
      title: 'Get Order Tracking',
    },
  },
  {
    path: '/settings/order-tracking/customizations-editor',
    component: () => import('@/pages/Tracking/OrderTracking/TrackingCustomizations/Editor.vue'),
    name: 'Tracking Customizations',
    beforeEnter(to, from, next) {
      if (store.getters.getSetting(shopSettings.ORDER_TRACKING_ALLOWED)) {
        next();
      } else {
        next('/settings/order-tracking');
      }
    },
    meta: {
      layout: 'app',
      layoutType: 'full',
      showBanner: false,
      title: 'Tracking Customizations',
      about: 'Manage tracking customizations',
      icon: 'settings-tracking',
      color: '#999999',
    }
  },
  {
    path: '/settings/return-and-exchange-tracking',
    component: () => import('@/pages/Tracking/ReturnAndExchangeTrackingSetupPage'),
    name: 'Return and Exchange Tracking',
    meta: {
      layout: 'app',
      title: 'Return And Exchange Tracking',
      skeleton: () => SkeletonLayout,
      about: 'Manage tracking settings.',
      icon: 'settings-tracking',
      color: '#999999',
    }
  },
  {
    path: '/settings/tracking/email-config',
    component: () => import('@/pages/Tracking/ForwardTrackingEmailConfig.vue'),
    name: 'Order Tracking Email Configuration',
    beforeEnter(to, from, next) {
      if (store.getters.getSetting(shopSettings.ORDER_TRACKING_ALLOWED)) {
        next();
      } else {
        next('/settings/tracking');
      }
    },
    meta: {
      layout: 'app',
      title: 'Order Tracking Email Configuration',
      about: '',
      skeleton: () => SkeletonLayout,
    }
  },
  {
    path: '/settings/rules',
    component: () => import('@/pages/Rules/RulesList'),
    name: 'Policy Rules',
    meta: {
      layout: 'app',
      title: 'Policy rules',
      skeleton: () => SkeletonLayout,
      about: 'Manage policy specific rules.',
      icon: 'shield',
      color: '#D7605C',
      action: 'rules/getPageData'
    }
  },
];
